import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
  } from '@backstage/theme';
  
  export const DFBTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: '#2cad80',
        },
        secondary: {
          main: '#19654C',
        },
        error: {
          main: '#8c4351',
        },
        warning: {
          main: '#8f5e15',
        },
        info: {
          main: '#34548a',
        },
        success: {
          main: '#485e30',
        },
        background: {
          default: '#ffffff',
          paper: '#ffffff',
        },
        banner: {
          info: '#34548a',
          error: '#8c4351',
          text: '#343b58',
          link: '#565a6e',
        },
        errorBackground: '#8c4351',
        warningBackground: '#8f5e15',
        infoBackground: '#343b58',
        navigation: {
          background: '#2cad80',
          indicator: '#ffffff',
          color: '#000000',
          selectedColor: '#ffffff',
          navItem: {
            hoverBackground: '#19654C',
            }
        },
      },
    }),
    defaultPageTheme: 'home',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#2cad80', '#19654C'], shape: shapes.wave }),
      documentation: genPageTheme({
        colors: ['#2cad80', '#19654C'],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({ colors: ['#2cad80', '#19654C'], shape: shapes.round }),
      service: genPageTheme({
        colors: ['#2cad80', '#19654C'],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: ['#2cad80', '#19654C'],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: ['#2cad80', '#19654C'],
        shape: shapes.wave,
      }),
      other: genPageTheme({ colors: ['#2cad80', '#19654C'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#2cad80', '#19654C'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#2cad80', '#19654C'], shape: shapes.wave }),
    },
  });