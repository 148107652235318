import React from 'react';
import { makeStyles } from '@material-ui/core';
import DFBLogoFull from './logo/DFB-Logo_Picturemark_RGB.png';

const useStyles = makeStyles({
  img: {
    width: '50px',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return <img src={DFBLogoFull} alt="DFB Logo" className={classes.img}/>;
};

export default LogoFull;
